import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cookie from 'react-cookies'

import AppRouter from './routes'
import MyAlert from '../shared/alert'
import Loader from '../shared/loader'
import Header from '../screens/_partials/header'
import Footer from '../screens/_partials/footer'
import SidePane from '../screens/_partials/sidebar'
import { listKeys } from '../helpers/extract-keys'

import { rememberToken } from '../state/users/userSlice'
import { useLocation, useNavigate } from 'react-router'
import { checkApiConfig } from '../state/root/rootSlice'
import Swal from 'sweetalert2'

const Main = () => {
  const [toggled, setToggled] = useState(false);
  const [topToggle, setTopToggle] = useState(false);
  const error = useSelector((state) => state.root.error)
  const successMessage = useSelector((state) => state.root.success_message)
  const rmLoading = useSelector((state) => state.users.rm_loading)
  const userFetch = useSelector((state) => state.users.currentUser)
  const userData = userFetch || (sessionStorage.user && JSON.parse(sessionStorage.user));
  const apiConfig = useSelector((state) => state.root.api_config)
  const location = useLocation();

  // const rememberedUser = useSelector((state) => state.users.rememberedUser)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const page = document.documentElement
  const alert_key = listKeys('error', 'success_message').join(':')

  useEffect(() => {
    // console.log(apiConfig);
    if (apiConfig?.maintenance_mode) {
      const defaultMessage = `
        We are currently unable to reach the server.<br/>
        Please try again later; we are sorry for the inconvenience.
        
      `
      const maintenanceMessage = apiConfig.maintenance_message
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>')
        .replaceAll('&#39;', "'");

      Swal.fire({
        icon: "warning",
        title: 'Temporary Service Interruption/Maintenance',
        html: maintenanceMessage || defaultMessage,
        backdrop: `
          rgba(0,0,0,0.4)
        `,
        showConfirmButton: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    } else if (apiConfig?.maintenance_message) {
        let popups = sessionStorage.getItem("popups");
        const {hide_maintenance} = JSON.parse(popups) || { hide_maintenance: false };
        // If session storage contains hide_maintenance flag as true then never show popup
        if (hide_maintenance) { return }

        Swal.fire({
          icon: "info",
          title: 'Alert',
          html: apiConfig.maintenance_message
            .replaceAll('&lt;', '<')
            .replaceAll('&gt;', '>')
            .replaceAll('&#39;', "'"),
          backdrop: `
            rgba(0,0,0,0.4)
          `,
          confirmButtonText: "Okay",
          confirmButtonColor: "rgb(1, 128, 117)",
          // showConfirmButton: false,
          showDenyButton: true,
          denyButtonText: 'Hide Prompt',
          allowEnterKey: false,
          allowEscapeKey: false,
          // allowOutsideClick: false,
          // footer: '<a href="#">Why do I have this issue?</a>'
        }).then((result) => {
          if (result.isDenied) {
            sessionStorage.setItem("popups", JSON.stringify({ hide_maintenance: true }));
          }
        });
      }
  }, [apiConfig])

  useEffect(() => {
    // Detect User OS and prompt all users below windows 10
    const userOS = navigator.userAgent
    const regex = /windows nt 10/gmi
    // Annoy all users below windows 10
    if (!userOS.match(regex)) {
      Swal.fire({
        icon: "info",
        title: 'Alert',
        html: `<b>End of Support for DigiPay on Windows 7 and Legacy Browsers.</b>
          <br></br>As part of our ongoing commitment to providing a secure, reliable, and efficient payment platform, DigiPay will no longer support Windows 7 PCs and legacy browsers.`,
        backdrop: `
          rgba(0,0,0,0.4)
        `,
        confirmButtonText: "Okay",
        confirmButtonColor: "rgb(1, 128, 117)",
        // showConfirmButton: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  }, [])

  // Fetch API Configuration Data on every page load
  useEffect(() => {
    dispatch(checkApiConfig());
  }, [dispatch, location])

  useEffect(() => {
    const rememberObj = cookie.load('rememberFinanceObj') || {}

    if (!sessionStorage.user && rememberObj?.remember_token) {
      dispatch(rememberToken(rememberObj.remember_token)).unwrap()
        .then(() => {
          sessionStorage.setItem("user", JSON.stringify(userData));
          navigate('/dashboard');
        })
        .catch(() => {
          cookie.remove("rememberFinanceObj", { path: "/" });
          navigate('/login');
        })
    }
  }, [dispatch, navigate, userData])

  useEffect(() => {
    if (error && typeof(error) == 'object' && error?.error?.toLowerCase() == 'unauthorized') { navigate('/logout'); }
    if (error && typeof(error) == 'string' && error?.toLowerCase() == 'unauthorized') { navigate('/logout'); }

    // console.log('err', error);
  }, [error, navigate])

  // useEffect(() => {
  //   if(rememberedUser === 'yes') {
  //     sessionStorage.setItem("user", JSON.stringify(userData));
  //     navigate('/dashboard');
  //   } else if(rememberedUser === 'no') {
  //     cookie.remove("rememberFinanceObj", { path: "/" });
  //     navigate('/login');
  //   }
  // }, [rememberedUser, navigate])


  const togglePanel = () => {
    setToggled(!toggled)
    page.classList.toggle('nav_open', !toggled)
  }

  const openTopBar = () => {
    setTopToggle(!topToggle )
    page.classList.toggle('topbar_open', !toggled)
  }

  const component = userData ? (
    <div className={`wrapper ${toggled ? 'sidebar_minimize' : ''}`}>
      <Header toggled={toggled} togglePanel={togglePanel} openTopBar={openTopBar} />
      <SidePane user={userData} toggled={toggled} topToggle={topToggle} />
      <div className="main-panel">
        <AppRouter user={userData}/>
        <Footer />
      </div>
    </div>
  ) : <AppRouter user={userData} />

  return rmLoading ? <Loader loading={true} /> : (
      <React.Fragment>
        <MyAlert key={alert_key} error={error} successMessage={successMessage} />
        {component}
      </React.Fragment>
    )
}

export default Main

