import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { userLogin, rememberToken, forgetPassword, resetPassword, getUser, getUsers, getRoles, saveUser, updateUser, updateProfile } from "../users/userSlice";
import { getReports, saveReport, voidReport } from "../reports/reportSlice";
import { getTransaction, getTransactions, voidTransaction, saveTransaction, updateTransaction } from "../transactions/transactionSlice";
import { getData } from "../search/searchSlice";
import { getLineItem, getLineItems, getRevenueLineItems, saveLineItem, updateLineItem } from "../lineitems/lineItemSlice";
import { getInvoice, getInvoices, getLocations, saveInvoice, updateInvoice, removeInvoice } from "../invoices/invoiceSlice";
import { getHead, getHeads, saveHead, updateHead } from "../heads/headSlice";
import { getDbData } from "../dashboard/dashboardSlice";
import { getCompanies, getCompany, saveCompany, updateCompany, removeCompany } from "../companies/companySlice";
import { saveReplayEmail, saveReplaySms, getAllRevenue, saveCashPayment, savePayee } from "../cash/cashSlice";
import axios from "axios";

const headers = () => {
  // let user = sessionStorage.getItem("user");
  // user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    // Authorization: user.auth_token,
  };
}

const initialState = {
  loading: false,
  success: false,
  success_message: null,
  api_config: null,
  error: null,
}

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // ** Root Slice **
    // Check API Configuration
      .addCase(checkApiConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkApiConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.api_config = action?.payload?.data;
        state.error = undefined;
      })
      .addCase(checkApiConfig.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action?.payload?.error;
      })
    // ** User Slice **
    // User Login
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = "Successfully logged in.";
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // RememberToken
      // .addCase(rememberToken.pending, (state) => {
      //   state.rm_loading = true;
      // })
      .addCase(rememberToken.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = "Welcome back";
      })
      .addCase(rememberToken.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Forget Password
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = "Password reset link has been sent please check your mail";
        // console.log(action);
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Reset Password
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = "Password has been updated";
        // console.log(action);
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Get User
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.error = undefined;
        state.userData = action.payload.data;
        // console.log(action);
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.currentUser = null;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Get Users
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.error = undefined;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Get Roles
      .addCase(getRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.error = undefined;
        // console.log(action);
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Save User
      .addCase(saveUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'User successfully created';
        state.error = undefined;
        // console.log(action);
      })
      .addCase(saveUser.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Update User
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'User successfully updated';
        state.error = undefined;
        // console.log(action);
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Update User Profile
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated';
        state.error = undefined;
        // console.log(action);
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Reports Slice
    // Get Reports
      .addCase(getReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getReports.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.transactions = [];
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Report
      .addCase(saveReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveReport.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'The report is building. Please check your email for updates.';
      })
      .addCase(saveReport.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.company = null;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Void Report
      .addCase(voidReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(voidReport.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Transaction successfully voided';
      })
      .addCase(voidReport.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Transactions Slice
    // Get Transactions
      .addCase(getTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.error = undefined
        // console.log(action);
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Transaction
      .addCase(getTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.error = undefined
        // console.log(action);
      })
      .addCase(getTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Transaction
      .addCase(saveTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Payment Successful';
        state.error = undefined
        // console.log(action);
      })
      .addCase(saveTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Void Transactions
      .addCase(voidTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(voidTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Transaction successfully voided';
        state.error = undefined
        // console.log(action);
      })
      .addCase(voidTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Transactions
      .addCase(updateTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Transaction successfully updated';
        state.error = undefined
        // console.log(action);
      })
      .addCase(updateTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // SEARCH SLICE
    // Get Data
      .addCase(getData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getData.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getData.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // LineItem Slice
    // Get Line Items
      .addCase(getLineItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLineItems.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        // console.log(action);
      })
      .addCase(getLineItems.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Revenue Line Items
      .addCase(getRevenueLineItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRevenueLineItems.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        // console.log(action);
      })
      .addCase(getRevenueLineItems.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Line Item
      .addCase(getLineItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLineItem.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        // console.log(action);
      })
      .addCase(getLineItem.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Line Item
      .addCase(saveLineItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveLineItem.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully created line item.';
        // console.log(action);
      })
      .addCase(saveLineItem.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Line Item
      .addCase(updateLineItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLineItem.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated line item.';
        // console.log(action);
      })
      .addCase(updateLineItem.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Invoice Slice
    // Get Invoices
      .addCase(getInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Invoice
      .addCase(getInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Invoice
      .addCase(getLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Invoice
      .addCase(saveInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully created invoice';
      })
      .addCase(saveInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Invoice
      .addCase(updateInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated invoice';
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Remove Invoice
      .addCase(removeInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully removed invoice';
      })
      .addCase(removeInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Heads Slice
    // Get Heads
      .addCase(getHeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHeads.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getHeads.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.heads = null;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Head
      .addCase(getHead.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHead.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getHead.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Head
      .addCase(saveHead.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveHead.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully created header.';
      })
      .addCase(saveHead.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Head
      .addCase(updateHead.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHead.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated header.';
      })
      .addCase(updateHead.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Dashboard SLice
    // Get Dashboard Data
      .addCase(getDbData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDbData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Dashboard successfully loadded';
      })
      .addCase(getDbData.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Company Slice
    // Get Companies
      .addCase(getCompanies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Companies
      .addCase(getCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Company
      .addCase(saveCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully created company.';
      })
      .addCase(saveCompany.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Company
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated company.';
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Remove Company
      .addCase(removeCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully removed company.';
      })
      .addCase(removeCompany.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
      // Cash Slice
    // Get Revenue Line Items
      .addCase(getAllRevenue.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRevenue.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
      })
      .addCase(getAllRevenue.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Cash Payment
      .addCase(saveCashPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveCashPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Payment Successful';
      })
      .addCase(saveCashPayment.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Payee
      .addCase(savePayee.pending, (state) => {
        state.loading = true;
      })
      .addCase(savePayee.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Payment Successful';
      })
      .addCase(savePayee.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Replay SMS
      .addCase(saveReplaySms.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveReplaySms.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Your confirmation has been sent.';
      })
      .addCase(saveReplaySms.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
    // Replay Email
      .addCase(saveReplayEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveReplayEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Your confirmation has been sent.';
      })
      .addCase(saveReplayEmail.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        //console.log('error', action);
        state.error = action.payload.error;
      })
  }
})


// API Configuration
export const checkApiConfig = createAsyncThunk(
  'root/checkApiConfig',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/api_configs`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const { clearError, errorLog, stopLoader } = rootSlice.actions
export default rootSlice.reducer;
